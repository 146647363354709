import React from "react"

import { FaMapMarkerAlt, FaEnvelope, FaPhone } from "react-icons/fa"
import styles from "../styles/contactos.module.scss"

import Layout from "../components/Layout"
import CallToAction from "../components/CallToAction"

const Contactos = () => {
  return (
    <Layout>
      <div className={styles.wrapper}>
        <div className={styles.contactsWrapper}>
          <h1>Contactos</h1>
          <div className={styles.contacts}>
            <div className={styles.contact}>
              <FaMapMarkerAlt size="2.8rem" />
              <h3>R. Cónego Alfredo Ferreira Morais Martins</h3>
            </div>
            <div className={styles.contact}>
              <FaEnvelope size="2.8rem" />
              <h3>freguesiadotouro@hotmail.com</h3>
            </div>
            <div className={styles.contact}>
              <FaPhone size="2.8rem" />
              <h3>232 602 304</h3>
            </div>
          </div>
        </div>
        <div className={styles.contactsWrapper}>
          <h1>Contactos Úteis</h1>
          <div className={styles.contacts}>
            <div className={styles.contact}>
              <h2>Bombeiros - Vila Nova de Paiva</h2>
              <div className={styles.contactNumber}>
                <FaPhone size="2.8rem" />
                <h3>232 604 181</h3>
              </div>
            </div>

            <div className={styles.contact}>
              <h2>GNR - Vila Nova de Paiva</h2>
              <div className={styles.contactNumber}>
                <FaPhone size="2.8rem" />
                <h3>232 604 139</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CallToAction
        title="Necessita de submeter um Requerimento?"
        buttonLabel="Submeter Requerimento"
        url={"/novo-requerimento"}
      />
    </Layout>
  )
}

export default Contactos
