import React from "react"
import { Link } from "gatsby"
import callToActionStyles from "./index.module.scss"

import Button from "../Button"

const CallToAction = ({ title, buttonLabel, url }) => {
  return (
    <div className={callToActionStyles.callToActionWrapper}>
      <div className={callToActionStyles.callToAction}>
        <h1>{title}</h1>
        <div className={callToActionStyles.callToActionButtonWrapper}>
          <Link to={url}>
            <Button>{buttonLabel}</Button>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default CallToAction
